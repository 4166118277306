@charset "UTF-8";
/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: '←';
}

[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: '→';
}

[dir='rtl'] .slick-next:before {
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    width: 100%;
    padding: 10px 5px;
    margin: 0;
    list-style: none;
    text-align: left;
}

@media only screen and (max-width: 1020px) {
    .slick-dots {
        text-align: center;
    }
}

.slick-dots li {
    position: relative;
    display: inline-block;
    padding: 0;
    cursor: pointer;
}

@media only screen and (max-width: 1020px) {
    .slick-dots li {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
    }
}

.slick-dots li button {
    width: 120px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

@media only screen and (max-width: 1020px) {
    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
    }
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    text-align: center;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 1020px) {
    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        color: #ccc;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-size: 13px;
    }
}

.slick-dots li.slick-active button:before {
    opacity: 1;
}

@media only screen and (max-width: 1020px) {
    .slick-dots li.slick-active button:before {
        color: #626265;
    }
}

/*↓メインビジュアル用設定↓*/
.main_visual {
    margin-bottom: 0px !important;
    /* Slider */
    /* Icons */
    /* Arrows */
    /* Dots */
}

.main_visual .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
}

@font-face {
    .main_visual {
        font-family: 'slick';
        font-weight: normal;
        font-style: normal;
        src: url("./fonts/slick.eot");
        src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
    }
}

.main_visual .slick-prev,
.main_visual .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.main_visual .slick-prev:hover,
.main_visual .slick-prev:focus,
.main_visual .slick-next:hover,
.main_visual .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.main_visual .slick-prev:hover:before,
.main_visual .slick-prev:focus:before,
.main_visual .slick-next:hover:before,
.main_visual .slick-next:focus:before {
    opacity: 1;
}

.main_visual .slick-prev.slick-disabled:before,
.main_visual .slick-next.slick-disabled:before {
    opacity: .25;
}

.main_visual .slick-prev:before,
.main_visual .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main_visual .slick-prev {
    left: -25px;
}

.main_visual [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.main_visual .slick-prev:before {
    content: '←';
}

.main_visual [dir='rtl'] .slick-prev:before {
    content: '→';
}

.main_visual .slick-next {
    right: -25px;
}

.main_visual [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.main_visual .slick-next:before {
    content: '→';
}

.main_visual [dir='rtl'] .slick-next:before {
    content: '←';
}

.main_visual .slick-dotted.slick-slider {
    margin-bottom: 0px;
}

.main_visual .slick-dots {
    position: absolute;
    bottom: 15px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.main_visual .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.main_visual .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.main_visual .slick-dots li button:hover,
.main_visual .slick-dots li button:focus {
    outline: none;
}

.main_visual .slick-dots li button:hover:before,
.main_visual .slick-dots li button:focus:before {
    opacity: 1;
}

.main_visual .slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: 13px;
}

.main_visual .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #626265;
}

/*↓new_arrival用設定↓*/
#new_arrival {
    /* Slider */
    /* Icons */
    /* Arrows */
    /* Dots */
}

#new_arrival .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
}

@font-face {
    #new_arrival {
        font-family: 'slick';
        font-weight: normal;
        font-style: normal;
        src: url("./fonts/slick.eot");
        src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
    }
}

#new_arrival .slick-prev,
#new_arrival .slick-next {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #626265;
    background-size: 90%;
    border-radius: 50%;
}

#new_arrival .slick-prev:hover,
#new_arrival .slick-prev:focus,
#new_arrival .slick-next:hover,
#new_arrival .slick-next:focus {
    /* color: transparent; */
    /* outline: none; */
    /* background: transparent; */
}

#new_arrival .slick-prev:hover:before,
#new_arrival .slick-prev:focus:before,
#new_arrival .slick-next:hover:before,
#new_arrival .slick-next:focus:before {
    opacity: 1;
}

#new_arrival .slick-prev.slick-disabled:before,
#new_arrival .slick-next.slick-disabled:before {
    opacity: 1;
}

#new_arrival .slick-prev:before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 17px;
    margin: 4px 3px 0 0;
    background: url(../img/common/arrow_01.png) no-repeat;
    background-size: contain;
    vertical-align: middle;
}

#new_arrival .slick-next:before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 18px;
    margin: 5px 0 0 3px;
    background: url(../img/common/arrow_02.png) no-repeat;
    background-size: contain;
    vertical-align: middle;
}

#new_arrival .slick-prev {
    left: -5%;
}

#new_arrival [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

#new_arrival .slick-prev:before {
    content: '';
}

#new_arrival [dir='rtl'] .slick-prev:before {
    content: '';
}

#new_arrival .slick-next {
    right: -5%;
}

#new_arrival [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

#new_arrival .slick-next:before {
    content: '';
}

#new_arrival [dir='rtl'] .slick-next:before {
    content: '';
}

#new_arrival .slick-dotted.slick-slider {
    margin-bottom: 30px;
}

#new_arrival .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

#new_arrival .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

#new_arrival .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

#new_arrival .slick-dots li button:hover,
#new_arrival .slick-dots li button:focus {
    outline: none;
}

#new_arrival .slick-dots li button:hover:before,
#new_arrival .slick-dots li button:focus:before {
    opacity: 1;
}

#new_arrival .slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: 13px;
}

#new_arrival .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #626265;
}
